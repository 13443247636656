





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getSearchLists } from '../api'
import * as model from '../models'
import { time_utils } from '@/utils/time_utils'
import listSell from '../components/list-sell.vue'
import dsPagination from '../components/ds-pagination.vue'
import contentBox from '../components/content-box.vue'
import segmentTab from '../components/segment-tab.vue'

interface TabsInfo {
  title: string
  type: string
}

@Component({
  components: {
    listSell,
    dsPagination,
    contentBox,
    segmentTab
  }
})
export default class SearchList extends Vue {
  @Watch('$route')
  onRouteChange(to: any, from: any) {
    this.renderSearchList()
  }

  searchStyle: object = {
    'width': '100%',
    'display': 'flex',
    'flex-direction': 'column',
    'align-items': 'center'
  }
  isLoading: boolean = true
  keyword: string = ''
  type: string = 'course'
  total: number = 0
  page: number = 1
  count: number = 10
  activeIndex: number = 0
  searchList: Array<model.Search> = []
  tabs: Array<TabsInfo> = [
    {
      title: '课程',
      type: 'course'
    },
    {
      title: '图文',
      type: 'article'
    },
    {
      title: '音频',
      type: 'audio'
    },
    {
      title: '视频',
      type: 'video'
    },
    {
      title: '直播',
      type: 'live'
    }
  ]

  mounted() {
    window.scrollTo(0, 0)
    this.renderSearchList()
  }

  get isHaveData() {
    return this.searchList.length
  }

  get getActiveIndex() {
    let i = 0
    this.tabs.forEach((m, n) => {
      if (m.type === this.type) {
        i = n
      }
    })
    return i
  }

  renderSearchList() {
    this.page = Number(this.$route.query.page) || 1
    this.count = Number(this.$route.query.count) || 10
    this.type = this.$route.query.type ? String(this.$route.query.type) : 'course'
    this.keyword = this.$route.query.keyword ? String(this.$route.query.keyword) : ''
    this.activeIndex = this.getActiveIndex
    this.getSearchList()
  }

  getSearchList() {
    this.isLoading = true
    getSearchLists(this.type, {
      q: this.keyword,
      page: this.page,
      size: this.count
    }).then(data => {
      console.log('getSearchList = ', data)
      data.data.map((m, n) => {
        m.lowest_price = m.lowest_price ? m.lowest_price / 100 : m.lowest_price
        m.lowest_original_price = m.lowest_original_price ? m.lowest_original_price / 100 : m.lowest_original_price
        m.highest_price = m.highest_price ? m.highest_price / 100 : m.highest_price
        m.highest_original_price = m.highest_original_price ? m.highest_original_price / 100 : 0
      })
      this.searchList = data.data
      this.total = data.page.total
      this.isLoading = false
    }).catch(res => {
      this.searchList = []
      this.total = 0
      this.isLoading = false
      console.error('getSearchList = ', res)
    })
  }

  getListSellItem(item: any) {
    let listSellItem: model.ListSell
    listSellItem = {
      content_id: item.id,
      type: item.category ? item.category.slug : item.content_type,
      title: item.name,
      brief: item.brief,
      indexpic: item.cover_image,
      update_time: time_utils.formatDate(item.update_time, 'yyyy-MM-dd').toString(),
      highest_price: item.highest_price,
      lowest_price: item.lowest_price,
      comment_count: item.comment_count,
      subscribe_count: item.subscribe_count,
      class_count: item.class_count,
      live_state: item.state,
      original_price: item.original_price
    }
    return listSellItem
  }

  onPageChange(val: number) {
    this.$router.push({
      name: 'search',
      query: {
        page: `${val}`,
        count: `${this.count}`,
        type: `${this.type}`,
        keyword: `${this.keyword}`
      }
    })
  }

  onChangeTab(val: number) {
    this.page = 1
    this.count = 10
    this.type = this.tabs[val].type
    this.$router.push({
      name: 'search',
      query: {
        page: `${this.page}`,
        count: `${this.count}`,
        type: `${this.type}`,
        keyword: `${this.keyword}`
      }
    })
  }
}
